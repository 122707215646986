import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
export default {
  name: 'Index',
  components: {
    [_Dialog.Component.name]: _Dialog.Component
  },
  data() {
    return {
      userInfo: {},
      buttonLoad: false,
      formData: {
        bankName: '',
        bankNumber: ''
      }
    };
  },
  async created() {
    this.getUserInfo();
  },
  async mounted() {},
  methods: {
    async getUserInfo() {
      let result = await this.$request({
        method: 'get',
        url: '/app/api/my/info'
      });
      if (result.code == 0) {
        // this.userInfo=result.data
        // this.formData.bankName=result.data.bank
        // this.formData.bankNumber=result.data.bank_card
        this.$store.commit('setUserInfo', result.data);
        this.formData.bankName = result.data.bankName;
        this.formData.bankNumber = result.data.bankNumber;
      }
    },
    async saveClick() {
      let that = this;
      if (!this.formData.bankName) {
        this.$toast({
          message: '请输入所属银行'
        });
        return false;
      }
      // if(!this.formData.bankNumber){
      //   this.$toast({message:'请输入银行卡号'})
      //   return false;
      // }

      this.buttonLoad = true;
      let result = await this.$request({
        method: 'post',
        url: '/app/api/my/info/update',
        data: this.formData
      });
      this.buttonLoad = false;
      if (result.code == 0) {
        this.$toast({
          message: '保存成功'
        });
        this.getUserInfo();
        this.$router.back();
        // Dialog.confirm({
        //   title: '保存成功',
        //   message: '是否去提现',
        // }).then(() => {
        //           // on confirm
        //   that.$router.replace('/apply')
        //  }).catch(() => {
        //           // on cancel
        //  });
      } else {
        this.$toast({
          message: result.msg
        });
      }
    }
  }
};