var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "info-div"
  }, [_c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("电话")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.$store.state.userInfo.phone))])])]), _c('div', {
    staticClass: "form-div"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("银行卡信息")]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("所属银行")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.bankName,
      expression: "formData.bankName"
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "请输入所属银行"
    },
    domProps: {
      "value": _vm.formData.bankName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "bankName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("银行卡号")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.bankNumber,
      expression: "formData.bankNumber"
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "请输入银行卡号"
    },
    domProps: {
      "value": _vm.formData.bankNumber
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "bankNumber", $event.target.value);
      }
    }
  })])]), _c('van-button', {
    staticClass: "button",
    attrs: {
      "color": "#C70C0C",
      "type": "danger",
      "block": ""
    },
    on: {
      "click": _vm.saveClick
    }
  }, [_vm._v("保存")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };